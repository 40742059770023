/**
 * Created by Profesor08 on 12.08.2017.
 */

.life-cycle {
  padding: 50px 0;

  h2 {
    font-size: 36px;
    font-weight: 300;
    color: #595959;
    text-align: center;
    margin-bottom: 70px;
  }

  h3 {
    font-size: 24px;
    font-weight: 300;
  }

  p {
    font-size: 18px;
    font-weight: 300;
  }

  .life-step {
    border-top: 9px solid #1AC6FF;
  }
}