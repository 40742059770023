/**
 * Created by Profesor08 on 03.08.2017.
 */

.dropdown-icon-mixin {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border: 2.5px solid #FFFFFF;
  border-top-color: transparent;
  border-right-color: transparent;
  transition: all 100ms ease;
  margin-left: 10px;
  transform: translateY(-50%) rotate(-45deg);
}

.header {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 13px 0;
  background: rgba(0, 0, 0, 0.2);

  nav {
    height: 37px;
    line-height: 37px;
    background: transparent;
    box-shadow: none;

    .brand-logo {
      height: 37px;
      line-height: 37px;
      z-index: 1;
      top: -8px;

      @media only screen and (max-width: 992px) {
        top: 0;
      }

      img {
        vertical-align: middle;
      }
    }

    .button-collapse, .button-collapse i {
      height: 37px;
      line-height: 37px;
    }

    li {
      line-height: normal;
      height: auto;
      white-space: nowrap;

      a {
        height: 34px;
        line-height: 34px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;

        &.user-panel {
          background: url(../images/user-2.png) no-repeat 15px center;
          color: #1ac6ff;
          padding-left: 45px;
        }

        &:hover {
          background-color: transparent;
          color: #1ac6ff;
        }
      }

      &.dropdown {
        position: relative;

        ul {
          display: none;
          position: absolute;
          top: 34px;
          left: 0;
          z-index: 1;
          min-width: 100%;
          background: #252525;

          li {
            min-width: 100%;

            a {
              display: inline-block;
              min-width: 100%;
              border-top: 1px solid rgb(29, 29, 29);
              border-bottom: 1px solid rgba(47, 47, 47, 0.86);
              background: url(../images/user-icon-2.png) no-repeat 15px center;
              background-size: 17px 17px;
              padding-left: 45px;

              &:hover {
                background-image: url(../images/user-icon-2-active.png);
              }
            }

            &:first-child {
              a {
                border-top: 0;
              }
            }

            &:last-child {
              a {
                border-bottom: 0;
              }
            }

            &.dropdown {
              &:after {
                .dropdown-icon-mixin();
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(-135deg);
              }

              a {
                padding-right: 30px;
              }

              ul {
                display: none;
                top: 0;
                left: 100%;

                li a {
                  padding-right: 15px;
                }
              }
            }

            ul {
              display: none;
              top: 0;
              left: 100%;
            }
          }
        }

        &.drop-left {
          .dropdown {
            ul {
              left: 1px;
              transform: translateX(-100%);
            }
          }
        }
      }
    }

    .nav-wrapper > ul > .dropdown {
      border: 0;

      &:before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        transition: all 100ms ease;
        background: #21c6fa;
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        transition: all 100ms ease;
        background: #252525;
        z-index: -1;
      }

      &.opened {

        &:before {
          height: 2px;
        }

        &:after {
          height: calc(~"100% + 2px");
        }

        .dropdown-icon {
          border-color: #1ac6ff;
          border-top-color: transparent;
          border-right-color: transparent;
        }
      }

      .dropdown-icon {
        .dropdown-icon-mixin();
      }
    }
  }
}