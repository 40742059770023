/**
 * Created by Profesor08 on 09.08.2017.
 */

.facts {
  background: url(../images/white-bg.jpg) center top;
  text-align: center;
  font-weight: 300;
  color: #1d1d1d;

  .outlined-block {
    display: inline-block;
    border: 1px solid #DDDDDD;
    margin-bottom: 20px;
    white-space: nowrap;
    max-width: 380px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    padding: 16px;
  }

  .sites {
    line-height: 1;
    text-align: left;

    .value {
      font-size: 48px;
      white-space: nowrap;
      color: #1AC6FF;
    }
  }

  .description {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .c100 + .description {
    margin-top: 20px;
  }

  .c100 {
    font-size: 200px;
    float: none;
    margin: 0 auto;

    span {
      font-size: 0.3em;
      width: 3.33em;
      line-height: 3.33em;
      font-weight: 100;
    }

    .bar, .fill {
      border-color: #1AC6FF;
    }

    &:after {
      top: 0.015em;
      left: 0.015em;
      width: 0.97em;
      height: 0.97em;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 300;
    margin: 65px 0 0 0;
    text-transform: uppercase;

    span {
      color: #1AC6FF;
    }
  }

  h3, .progress-container {
    font-size: 16px;
    font-weight: 300;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  h4 {
    margin-bottom: 85px;
  }

  .heading {
    color: #1AC6FF;
    font-size: 26px;
    font-weight: 300;

    + p {
      font-size: 20px;
    }
  }

  .progress-container {
    text-align: left;

    .s2 {
      padding: 0 .75rem 0 0;
      text-align: right;
    }
  }

  .round-diagrams {
    .progress-container {
      padding-top: 22px;
    }
  }
  .sites {
    h3 {
      font-size: 16px;
      font-weight: 300;
      line-height: 16px;
      margin: 0;

      span {
        display: block;
        font-size: 25px;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }

  .clients {
    margin-bottom: 30px;

    .value {
      font-size: 45px;
    }

    .description {
      font-size: 14px;
    }
  }

  &.call-center {

    h2 {
      text-transform: capitalize;
      font-weight: 300;
    }

    h2 + h3 {
      font-size: 16px;
      text-transform: none;
      margin: 10px 0 65px 0;
      font-weight: 300;
    }

    h2:only-child {
      margin: 65px 0;
    }

    .sites {
      text-align: left;
      font-weight: 300;

      h3 {
        font-weight: 300;
      }

      .value {
        display: block;
      }

      .description {
        font-size: 14px;
        display: inline-block;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &.bordered {
        border: 1px solid #DDDDDD;
        margin-bottom: 20px;
        white-space: nowrap;
        max-width: 380px;

        .col {
          padding-left: 15px;
        }

        .value {
          font-size: 48px;
          display: inline-block;
        }

        .description {
          font-size: 18px;
        }

        .row {
          margin-bottom: 0;
          padding: 10px 0;
        }

        h3 {
          padding: 8px 0;
          font-weight: 300;
        }
      }
    }
  }

  &.people-on-road {
    background:
            url(../images/shadow-top.png) repeat-x top,
            url(../images/shadow-bottom.png) repeat-x bottom,
            url(../images/info-section/people-on-road.jpg) no-repeat center center;
    background-size: auto, auto, cover;
    color: #FFFFFF;
  }

  &.beautiful-girl {
    background:
            url(../images/shadow-top.png) repeat-x top,
            url(../images/shadow-bottom.png) repeat-x bottom,
            url(../images/info-section/beautiful-girl.jpg) no-repeat center center;
    background-size: auto, auto, cover;
    color: #FFFFFF;
  }

  &.ancient-blocks {
    background:
            url(../images/shadow-top.png) repeat-x top,
            url(../images/shadow-bottom.png) repeat-x bottom,
            url(../images/info-section/ancient-blocks.jpg) no-repeat center center;
    background-size: auto, auto, cover;
    color: #FFFFFF;
  }

  &.buildings-in-fog {
    background:
            url(../images/shadow-top.png) repeat-x top,
            url(../images/shadow-bottom.png) repeat-x bottom,
            url(../images/info-section/buildings-in-fog.jpg) no-repeat center center;
    background-size: auto, auto, cover;
    color: #FFFFFF;
  }

  &.people-icons {
    .human-group {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.facts-city-bg {
    background:
            url(../images/shadow-top.png) repeat-x top,
            url(../images/shadow-bottom.png) repeat-x bottom,
            url(../images/facts/facts-city-bg.png) no-repeat center center;
    background-size: auto, auto, cover;
    color: #FFFFFF;
  }
}

