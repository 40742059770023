/**
 * Created by Profesor08 on 03.08.2017.
 */

body, html {
  margin: 0;
  padding: 0;
}

.container {
  width: 1000px;
}

section {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 85%
  }
}

@media only screen and (max-width: 992px) {
  .container {
    width: 70%
  }
}

@media only screen and (max-width: 1199px) {
  .container {
    width: 90%
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    width: 1280px;
  }
}

.animated {
  animation-fill-mode: none;
}

.d-none {
  display: none!important
}

.d-inline {
  display: inline!important
}

.d-inline-block {
  display: inline-block!important
}

.d-block {
  display: block!important
}

.d-table {
  display: table!important
}

.d-table-cell {
  display: table-cell!important
}

.d-flex {
  display: -webkit-box!important;
  display: -webkit-flex!important;
  display: -ms-flexbox!important;
  display: flex!important
}

.d-inline-flex {
  display: -webkit-inline-box!important;
  display: -webkit-inline-flex!important;
  display: -ms-inline-flexbox!important;
  display: inline-flex!important
}

@media (min-width: 576px) {
  .d-sm-none {
    display:none!important
  }

  .d-sm-inline {
    display: inline!important
  }

  .d-sm-inline-block {
    display: inline-block!important
  }

  .d-sm-block {
    display: block!important
  }

  .d-sm-table {
    display: table!important
  }

  .d-sm-table-cell {
    display: table-cell!important
  }

  .d-sm-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
  }
}

@media (min-width: 601px) {
  .d-md-none {
    display:none!important
  }

  .d-md-inline {
    display: inline!important
  }

  .d-md-inline-block {
    display: inline-block!important
  }

  .d-md-block {
    display: block!important
  }

  .d-md-table {
    display: table!important
  }

  .d-md-table-cell {
    display: table-cell!important
  }

  .d-md-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important
  }

  .d-md-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display:none!important
  }

  .d-lg-inline {
    display: inline!important
  }

  .d-lg-inline-block {
    display: inline-block!important
  }

  .d-lg-block {
    display: block!important
  }

  .d-lg-table {
    display: table!important
  }

  .d-lg-table-cell {
    display: table-cell!important
  }

  .d-lg-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display:none!important
  }

  .d-xl-inline {
    display: inline!important
  }

  .d-xl-inline-block {
    display: inline-block!important
  }

  .d-xl-block {
    display: block!important
  }

  .d-xl-table {
    display: table!important
  }

  .d-xl-table-cell {
    display: table-cell!important
  }

  .d-xl-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
  }
}

.flex-row {
  -webkit-box-orient: horizontal!important;
  -webkit-box-direction: normal!important;
  -webkit-flex-direction: row!important;
  -ms-flex-direction: row!important;
  flex-direction: row!important
}

.flex-column {
  -webkit-box-orient: vertical!important;
  -webkit-box-direction: normal!important;
  -webkit-flex-direction: column!important;
  -ms-flex-direction: column!important;
  flex-direction: column!important
}

.flex-row-reverse {
  -webkit-box-orient: horizontal!important;
  -webkit-box-direction: reverse!important;
  -webkit-flex-direction: row-reverse!important;
  -ms-flex-direction: row-reverse!important;
  flex-direction: row-reverse!important
}

.flex-column-reverse {
  -webkit-box-orient: vertical!important;
  -webkit-box-direction: reverse!important;
  -webkit-flex-direction: column-reverse!important;
  -ms-flex-direction: column-reverse!important;
  flex-direction: column-reverse!important
}

.flex-wrap {
  -webkit-flex-wrap: wrap!important;
  -ms-flex-wrap: wrap!important;
  flex-wrap: wrap!important
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap!important;
  -ms-flex-wrap: nowrap!important;
  flex-wrap: nowrap!important
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse!important;
  -ms-flex-wrap: wrap-reverse!important;
  flex-wrap: wrap-reverse!important
}

.justify-content-start {
  -webkit-box-pack: start!important;
  -webkit-justify-content: flex-start!important;
  -ms-flex-pack: start!important;
  justify-content: flex-start!important
}

.justify-content-end {
  -webkit-box-pack: end!important;
  -webkit-justify-content: flex-end!important;
  -ms-flex-pack: end!important;
  justify-content: flex-end!important
}

.justify-content-center {
  -webkit-box-pack: center!important;
  -webkit-justify-content: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important
}

.justify-content-between {
  -webkit-box-pack: justify!important;
  -webkit-justify-content: space-between!important;
  -ms-flex-pack: justify!important;
  justify-content: space-between!important
}

.justify-content-around {
  -webkit-justify-content: space-around!important;
  -ms-flex-pack: distribute!important;
  justify-content: space-around!important
}

.align-items-start {
  -webkit-box-align: start!important;
  -webkit-align-items: flex-start!important;
  -ms-flex-align: start!important;
  align-items: flex-start!important
}

.align-items-end {
  -webkit-box-align: end!important;
  -webkit-align-items: flex-end!important;
  -ms-flex-align: end!important;
  align-items: flex-end!important
}

.align-items-center {
  -webkit-box-align: center!important;
  -webkit-align-items: center!important;
  -ms-flex-align: center!important;
  align-items: center!important
}

.align-items-baseline {
  -webkit-box-align: baseline!important;
  -webkit-align-items: baseline!important;
  -ms-flex-align: baseline!important;
  align-items: baseline!important
}

.align-items-stretch {
  -webkit-box-align: stretch!important;
  -webkit-align-items: stretch!important;
  -ms-flex-align: stretch!important;
  align-items: stretch!important
}

.align-content-start {
  -webkit-align-content: flex-start!important;
  -ms-flex-line-pack: start!important;
  align-content: flex-start!important
}

.align-content-end {
  -webkit-align-content: flex-end!important;
  -ms-flex-line-pack: end!important;
  align-content: flex-end!important
}

.align-content-center {
  -webkit-align-content: center!important;
  -ms-flex-line-pack: center!important;
  align-content: center!important
}

.align-content-between {
  -webkit-align-content: space-between!important;
  -ms-flex-line-pack: justify!important;
  align-content: space-between!important
}

.align-content-around {
  -webkit-align-content: space-around!important;
  -ms-flex-line-pack: distribute!important;
  align-content: space-around!important
}

.align-content-stretch {
  -webkit-align-content: stretch!important;
  -ms-flex-line-pack: stretch!important;
  align-content: stretch!important
}

.align-self-auto {
  -webkit-align-self: auto!important;
  -ms-flex-item-align: auto!important;
  align-self: auto!important
}

.align-self-start {
  -webkit-align-self: flex-start!important;
  -ms-flex-item-align: start!important;
  align-self: flex-start!important
}

.align-self-end {
  -webkit-align-self: flex-end!important;
  -ms-flex-item-align: end!important;
  align-self: flex-end!important
}

.align-self-center {
  -webkit-align-self: center!important;
  -ms-flex-item-align: center!important;
  align-self: center!important
}

.align-self-baseline {
  -webkit-align-self: baseline!important;
  -ms-flex-item-align: baseline!important;
  align-self: baseline!important
}

.align-self-stretch {
  -webkit-align-self: stretch!important;
  -ms-flex-item-align: stretch!important;
  align-self: stretch!important
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient:horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
  }

  .flex-sm-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
  }

  .flex-sm-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
  }

  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
  }

  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
  }

  .justify-content-sm-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
  }

  .justify-content-sm-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
  }

  .justify-content-sm-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
  }

  .justify-content-sm-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
  }

  .align-items-sm-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
  }

  .align-items-sm-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
  }

  .align-items-sm-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
  }

  .align-content-sm-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
  }

  .align-content-sm-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
  }

  .align-content-sm-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important
  }

  .align-content-sm-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
  }

  .align-content-sm-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
  }

  .align-content-sm-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
  }

  .align-self-sm-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important
  }

  .align-self-sm-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
  }

  .align-self-sm-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
  }

  .align-self-sm-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important
  }

  .align-self-sm-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
  }

  .align-self-sm-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
  }
}

@media (min-width: 601px) {
  .flex-md-row {
    -webkit-box-orient:horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
  }

  .flex-md-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
  }

  .flex-md-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
  }

  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
  }

  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
  }

  .justify-content-md-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
  }

  .justify-content-md-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
  }

  .justify-content-md-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
  }

  .justify-content-md-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
  }

  .justify-content-md-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
  }

  .align-items-md-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
  }

  .align-items-md-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
  }

  .align-items-md-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
  }

  .align-content-md-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
  }

  .align-content-md-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
  }

  .align-content-md-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important
  }

  .align-content-md-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
  }

  .align-content-md-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
  }

  .align-content-md-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
  }

  .align-self-md-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important
  }

  .align-self-md-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
  }

  .align-self-md-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
  }

  .align-self-md-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important
  }

  .align-self-md-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
  }

  .align-self-md-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient:horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
  }

  .flex-lg-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
  }

  .flex-lg-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
  }

  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
  }

  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
  }

  .justify-content-lg-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
  }

  .justify-content-lg-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
  }

  .justify-content-lg-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
  }

  .justify-content-lg-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
  }

  .align-items-lg-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
  }

  .align-items-lg-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
  }

  .align-items-lg-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
  }

  .align-content-lg-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
  }

  .align-content-lg-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
  }

  .align-content-lg-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important
  }

  .align-content-lg-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
  }

  .align-content-lg-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
  }

  .align-content-lg-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
  }

  .align-self-lg-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important
  }

  .align-self-lg-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
  }

  .align-self-lg-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
  }

  .align-self-lg-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important
  }

  .align-self-lg-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
  }

  .align-self-lg-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient:horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
  }

  .flex-xl-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
  }

  .flex-xl-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
  }

  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
  }

  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
  }

  .justify-content-xl-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
  }

  .justify-content-xl-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
  }

  .justify-content-xl-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
  }

  .justify-content-xl-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
  }

  .align-items-xl-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
  }

  .align-items-xl-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
  }

  .align-items-xl-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
  }

  .align-content-xl-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
  }

  .align-content-xl-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
  }

  .align-content-xl-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important
  }

  .align-content-xl-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
  }

  .align-content-xl-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
  }

  .align-content-xl-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
  }

  .align-self-xl-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important
  }

  .align-self-xl-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
  }

  .align-self-xl-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
  }

  .align-self-xl-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important
  }

  .align-self-xl-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
  }

  .align-self-xl-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
  }
}


@import "icons";
@import "ui";
@import "circle";
@import "accordion-list";
@import "info-section";
@import "form";
@import "design-list";
@import "side-nav";
@import "modals";

@import "info-text-section";

@import "facts";
@import "opportunities";

@import "soft-variants";
@import "partners";
@import "soft-images";
@import "section-form";
@import "scope";
@import "section-slider";
@import "section-tabs";
@import "specs";
@import "life-cycle";
@import "offers-section";
@import "video-section";
@import "videos";
@import "icon-on-circle";
@import "tabs-dynamic";
@import "counter-section";
@import "integration-info";
@import "instruments";
@import "requisites";

@import "header";
@import "main";
@import "about";
@import "core";
@import "programmers";
@import "infographics";
@import "services";
@import "services-small";
@import "brands";
@import "article";
@import "bank";
@import "promo";
@import "integrations";
@import "prices";
@import "footer";

.col {
  //opacity: 0;
}