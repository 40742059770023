/**
 * Created by Profesor08 on 09.08.2017.
 */

@color_1: #595959;
@color_2: #1AC6FF;
@color_3: #8d8d8d;
@color_4: #DDDDDD;

.prices {
  padding: 70px 0;
  text-align: center;
  background: url(../images/white-bg-2.jpg) center top;

  h2 {
    color: @color_1;
    font-size: 48px;
    font-weight: 300;
  }

  p {
    color: @color_3;
    margin-bottom: 118px;
  }

  h3 {
    color: @color_1;
    font-size: 36px;
    font-weight: 300;

    span {
      font-weight: 400;
    }
  }

  button {
    min-width: 60%;
  }

  .promo-card {
    border-top: 19px solid #EEEEEE;
    border-radius: 5px;
    background: #fcfcfc;
    position: relative;
    box-shadow: 0 2px 4.85px 0.15px rgba(161, 161, 161, 0.75);
    margin-top: 70px;

    &.hot {
      border-top-color: @color_2;
      border-bottom-color: #EEEEEE;
      box-shadow: 0 4px 14.94px 3.06px rgba(161, 161, 161, 0.75);
      z-index: 1;
    }

    .price {
      font-size: 22px;
      font-weight: 100;

      .value {
        font-size: 78px;
        font-weight: 400;
      }
    }

    .description {
      text-transform: uppercase;
      font-size: 16px;
      color: @color_2;
    }

    ul {
      border-top: 1px solid @color_4;
      padding-top: 10px;

      li {
        font-size: 16px;
        font-weight: 100;
        line-height: 29px;
        color: @color_3;
      }
    }

    .design-button {
      margin-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }

    .corner-tape {
      font-size: 10px;
      font-weight: 100;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 124px;
      height: 129px;
      background: url(../images/corner-tape.png) no-repeat;
      position: absolute;
      top: -29px;
      right: -9px;
      z-index: 3;

      span {
        display: inline-block;
        position: absolute;
        top: 46px;
        left: -4px;
        transform: rotate(45deg);
        width: 150px;
      }
    }
  }
}

@media only screen and (min-width: 601px) {
  .prices {
    .promo-card {
      &.hot {
        //transform: translateY(-37px);
        margin-top: 33px;
      }
    }
  }
}