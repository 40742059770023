/**
 * Created by Profesor08 on 10.08.2017.
 */

.accordion-list {
  padding: 0;

  h2 {
    color: #1ac6ff;
    font-size: 24px;
    text-transform: uppercase;
  }

  p {
    font-size: 16px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .container > .row {
    margin-bottom: 0;
  }

  .short-info {
    font-size: 18px;
    font-weight: 500;
  }

  .list-container {
    margin-bottom: 20px;
  }

  .collapsible {
    border: 0;
    box-shadow: none;


    .collapsible-header {
      padding: 3px 0 3px 0;
      background: transparent;
      border: 0;
      box-shadow: none;
      font-weight: 500;
      font-size: 15px;
      transition: ease-in-out transform 300ms;

      svg {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 3px 10px 0 0;
        transition: ease-in-out transform 300ms;
      }

      &:hover {
        color: #1ac6ff;

        svg {
          transform: scale(1.2);
          fill: #1ac6ff;
        }
      }

      &.active {
        color: #1ac6ff;

        svg {
          fill: #1ac6ff;
          transform: rotate(90deg);
        }
      }
    }

    .collapsible-body {
      padding: 0 0 0 110px;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 15px;
      border: 0;
      box-shadow: none;

      ul {
        li {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            display: block;
            left: -30px;
            top: 10px;
            width: 3px;
            height: 3px;
            background: #424242;
          }
        }
      }
    }
  }

  &.shop, &.hands-bg, &.payment-terminal {
    .image {
      align-items: flex-end;

      .responsive-img {
        width: 100%;
      }
    }
  }

  &.soft-box {
    .responsive-img {
      width: 100%;
    }

    padding: 0;

    .container {
      position: relative;
      padding: 50px 0;
    }

    .floating-icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: url(../images/cloud-shape-2.png) no-repeat;
      width: 137px;
      height: 146px;

      .text {
        position: absolute;
        text-align: center;
        bottom: 25px;
        font-size: 11px;
        color: #1ac6ff;
        padding: 0 20px;
      }
    }
  }
}

.article {
  h2 {
    font-size: 24px;
    color: #1ac6ff;
  }

}

@media only screen and (max-width: 760px) {
  .accordion-list {
    &.hands-bg {
      background-size: cover;
    }
  }
}

@media only screen and (min-width: 993px) {
  .accordion-list {
    .row {
      display: flex;
    }

    .image {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 992px) {
  .accordion-list {
    &.soft-box {
      .container {
        padding-top: 150px;
      }
    }
  }
}