/**
 * Created by Profesor08 on 15.08.2017.
 */


#sign-up {

  background: rgba(255,255,255,.8);
  border-radius: 10px;
  max-width: 500px;

   h2 {
    font-size: 30px;
    color: #3d3d3d;
    margin-bottom: 0;
     text-align: center;
  }

  p {
    font-size: 18px;
    color: #525252;
    margin-top: 0;
    text-align: center;
  }

  .design-form {
  }
}