/**
 * Created by Profesor08 on 03.08.2017.
 */

.services {
  display: flex;
  width: 100%;
  background: #f4f4f6;

  .side-left, .side-right {
    display: flex;
    vertical-align: top;
  }

  h3 {
    font-size: 25px;
    color: #474747;
  }

  .services-container {
    margin: 0 10%;
    width: 80%;
    padding-top: 30px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    align-items: center;

    .col {
      position: relative;

      order: 0;
      flex: 1 1 auto;
      align-self: auto;

      .service-wrapper {
        height: 221px;
        margin: 20px 20px 0 20px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        background: url(../images/service-shadow-2.png) no-repeat center bottom;
        background-size: 100% 100%;
        border-radius: 4px;
      }

      .service-container {
        text-align: center;
        width: 100%;
      }

      h4 {
        margin: 0;
        padding: 0;
        color: #4e4e4e;
        font-size: 19px;
      }
    }

    .service {
      display: table-cell;
      width: 30%;
    }
  }

  p {
    font-size: 14px;
    color: #4e4e4e;
    width: 90%;
    margin: 14px auto;
  }

  .articles {
    margin: 0 70px;
  }

  .article {
    padding-bottom: 50px;
  }

  .side-right {
    width: 416px;
    background: #2c2c2c;

    h3, p {
      color: #ffffff;
      font-weight: 300;
    }
  }

  .side-left {
    width: calc(~"100% - 416px");
  }

  .read-more {
    border-bottom: 1px solid #1ac6ff;

    a {
      color: #1ac6ff;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 300;
      line-height: 30px;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .services {
    display: block;

    .side-left, .side-right {
      display: block;
      vertical-align: top;
      width: 100%;
    }

    .services-container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }

    .articles {
      margin: 0;
      padding: 0 .75rem;

      .image, p {
        text-align: center;
        max-width: none;
      }

      h3 {
        font-size: 3.56rem;
        line-height: 110%;
        padding: 30px 0;
        text-align: center;
      }

      p {
        margin-bottom: 10px;
      }
    }

    .article {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
}