/**
 * Created by Profesor08 on 27.09.2017.
 */
.instruments {
  padding-bottom: 50px;

  background-image: url(../images/shadow-top.png),
  url(../images/shadow-bottom.png),
  url(../images/instruments.jpg);
  background-repeat: repeat-x, repeat-x, no-repeat;
  background-position: top, bottom, left top;
  background-size: auto, auto, cover;

  h2 {
    color: #ffffff;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin: 50px 0;
  }

  .number {
    font-size: 180px;
    font-weight: 300;
    color: #2cc5f2;
    line-height: 150px;
  }

  .block-text {
    font-size: 18px;
    color: #ffffff;
    font-weight: 300;
    margin: 0;
  }

  .first-row, .second-row {
    margin-bottom: 80px;
  }
}