/**
 * Created by Profesor08 on 08.09.2017.
 */

.tabs-dynamic {
  padding: 50px 0;
  font-weight: 300;
  min-height: 760px;

  background: url(../images/shadow-top.png) repeat-x top,
  url(../images/shadow-bottom.png) repeat-x bottom,
  url(../images/tabs/tabs-bg-1.jpg) no-repeat center center;
  background-size: auto, auto, cover;

  h2 {
    font-size: 48px;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
  }

  .row {
    display: flex;
    align-items: stretch;

    .col {
      position: relative;
    }

    @media only screen and (max-width: 992px) {
      display: block;
    }
  }

  .tab-container {
    background: rgba(0, 0, 0, .41);
    padding: 18px 27px;
    border-radius: 15px;
    font-size: 24px;

    p {
      color: #f1f1f1;

      strong {
        color: #FFFFFF;
      }
    }
  }

  .tabs {
    background: transparent;
    margin-bottom: 15px;

    @media only screen and (min-width: 993px) {
      display: flex;
      flex-direction: column;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    li {
      @media only screen and (min-width: 993px) {
        position: relative;
        cursor: default;
        width: 100%;
        height: 60px;
        margin: 0;
      }
    }

    a {
      color: #aeaeae;
      font-size: 33px;
      display: block;
      text-align: center;
      transition: ease all 250ms;
      padding: 0;

      @media only screen and (min-width: 993px) {
        text-transform: lowercase;
        text-overflow: clip;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &:hover {
        color: #FFFFFF;
      }

      &.active {
        color: #00aeff;
        font-size: 36px;
      }
    }

    .control-arrow {
      width: 63px;
      height: 42px;
      cursor: pointer;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);

      @media only screen and (max-width: 992px) {
        display: none;
      }

      &.next {
        background: url(../images/tab-arrow-bottom.png) no-repeat;
        transform: translate(-50%, 0);

        &:hover {
          background: url(../images/tab-arrow-bottom-hover.png) no-repeat;
        }
      }

      &.prev {
        background: url(../images/tab-arrow-top.png) no-repeat;

        &:hover {
          background: url(../images/tab-arrow-top-hover.png) no-repeat;
        }
      }
    }

    .indicator {
      background: #00aeff;
      display: none;

      @media only screen and (max-width: 992px) {
        display: block;
      }
    }

    @media only screen and (max-width: 992px) {
      a {
        font-size: 20px;

        &.active {
          font-size: 24px;
        }
      }

      .indicator {
        display: block;
      }
    }

    @media only screen and (max-width: 600px) {
      a {
        font-size: 12px;
        padding: 0 2px;

        &.active {
          font-size: 14px;
        }
      }
    }
  }
}