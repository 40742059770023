/**
 * Created by Profesor08 on 13.08.2017.
 */

.offers-section {
  color: #4e4e4e;

  h2 {
    font-size: 36px;
    font-weight: 300;
    color: #1ac6ff;
  }

  p {
    font-size: 16px;
  }

  .icon-sprite {
    vertical-align: middle;
    margin-bottom: 17px;
    font-size: 20px;
    font-weight: 300;
    height: 58px;

    &:before {
      transform: scale(1.2);
    }

    .text-centered {
      position: absolute;
      left: 70px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}