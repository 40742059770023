/**
 * Created by Profesor08 on 06.10.2017.
 */

.requisites {

  p {
    margin: 5px 0;
  }

  h3 {
    margin: 0;
  }

  .row {
    margin-bottom: 8px;
  }

  .entity-info {
    .row {
      border-top: 1px solid #e9ecef;
      padding-top: 8px;
    }
  }
}