/**
 * Created by Profesor08 on 10.08.2017.
 */

.partners {
  background: #F0F0F0;

  .container, .row {
    height: 100%;
    margin-bottom: 0;
  }

  .col {
    text-align: center;
  }

  h2 {
    font-size: 48px;
    color: #393939;
    text-align: center;
    font-weight: 300;
  }

  &.colored {
    padding: 50px 50px;
    background: transparent;

    .images-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;

      div {
        flex: 1 0 auto;
      }
    }
  }
}