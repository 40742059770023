/**
 * Created by Profesor08 on 03.08.2017.
 */

.programmers {
  height: 505px;
  position: relative;
  background: url(../images/arrow.png) no-repeat top center,
  url(../images/programmer-right-2.png) no-repeat bottom right,
  url(../images/programmer-left-3.png) no-repeat top left,
  url(../images/white-bg.jpg) center center;

  .container, .row, .col {
    height: 100%;
  }

  .info-card {
    p {
      color: #4a4a4a;
    }
  }

  @media only screen and (max-width: 599px) {
    background: url(../images/programmer-right-2.png) no-repeat bottom right,
    url(../images/programmer-left-3.png) no-repeat top left,
    url(../images/white-bg.jpg) center center;
  }
}