/**
 * Created by Profesor08 on 09.08.2017.
 */

.integrations {
  padding: 70px 0;

  h2 {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 70px 0;
    color: #595959;
  }

  .items-list {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-items: flex-start;
  }

  .item {
    padding-left: 80px;
    padding-right: 10px;
    background: no-repeat 10px top;

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 10px 0;
      color: #595959;
    }

    p {
      font-size: 13px;
      font-weight: 300;
      color: #8d8d8d;
      margin-top: 0;
    }

    &:nth-child(1) {
      background-image: url(../images/008-note.png);
    }

    &:nth-child(2) {
      background-image: url(../images/005-gear.png);
    }

    &:nth-child(3) {
      background-image: url(../images/002-cloud-computing.png);
    }

    &:nth-child(4) {
      background-image: url(../images/007-phone-call.png);
    }

    &:nth-child(5) {
      background-image: url(../images/004-speech-bubble.png);
    }

    &:nth-child(6) {
      background-image: url(../images/009-son.png);
    }

    &:nth-child(7) {
      background-image: url(../images/006-shield.png);
    }

    &:nth-child(8) {
      background-image: url(../images/003-letter.png);
    }

    &:nth-child(9) {
      background-image: url(../images/001-basket.png);
    }
  }

  &:not(.two-column) {
    .item {
      &:last-child {
        margin-left: 0;
      }
    }
  }

  &.multi-sections {
    h2 {
      font-size: 30px;
      color: #595959;
      margin-bottom: 0;
      text-align: center;
      text-transform: none;
    }

    h2 + h3 {
      font-size: 30px;
      color: #1AC6FF;
      text-align: center;
      margin: 0 0 50px 0;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
    }

    .item {
      background-image: url(../images/icons-blue/1.png);
    }
  }

  &.two-column {
    .item {

      &:nth-child(1) {
        background-image: url(../images/icons-blue/1.png);
      }

      &:nth-child(2) {
        background-image: url(../images/icons-blue/2.png);
      }

      &:nth-child(3) {
        background-image: url(../images/icons-blue/3.png);
      }

      &:nth-child(4) {
        background-image: url(../images/icons-blue/4.png);
      }

      &:nth-child(5) {
        background-image: url(../images/icons-blue/5.png);
      }

      &:nth-child(6) {
        background-image: url(../images/icons-blue/6.png);
      }

      &:nth-child(7) {
        background-image: url(../images/icons-blue/7.png);
      }

      &:nth-child(8) {
        background-image: url(../images/icons-blue/8.png);
      }
    }
  }

  &.trading {

    h2 {
      span {
        color: #858484;
        font-size: 24px;
      }
    }

    .item {
      margin-bottom: 30px;
    }
  }
}