/**
 * Created by Profesor08 on 08.08.2017.
 */

.articles {
  padding: 50px 0;
  background: url(../images/white-bg.jpg) center center;

  .carousel {
    height: auto !important;
    min-height: 320px;
  }

  .row {
    margin-bottom: -15px;
  }

  .article {
    height: auto !important;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 15px;
    //display: flex;

    .image {
      //flex: 0 0 171px;
      //width: 171px;
    }

    .article-text {
      color: #ffffff;
      font-weight: 300;
      padding: 5px 15px;

      p:first-child {
        margin: 0;
      }

      .read-more {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .owl-prev, .owl-next {
    position: absolute;
    top: 50%;
    left: 0;
    width: 23px;
    height: 43px;
  }

  .owl-prev {
    transform: translate(-100%, -50%);
    background: url(../images/carousel-prev.png) no-repeat;

    &:hover {
      background: url(../images/carousel-prev-active.png) no-repeat;
    }
  }

  .owl-next {
    left: 100%;
    transform: translate(0, -50%);
    background: url(../images/carousel-next.png) no-repeat;

    &:hover {
      background: url(../images/carousel-next-active.png) no-repeat;
    }
  }
}

.article.asdasd {
  font-family: "Roboto", sans-serif;
  font-weight: 400;

  h2 {
    font-size: 24px;
    color: #1ac6ff;
  }

  .short-info {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .collapsible {
    border: 0;
    box-shadow: none;

    .collapsible-header {
      padding: 3px 0 3px 0;
      background: transparent;
      border: 0;
      box-shadow: none;
      font-weight: 500;
      font-size: 15px;
      transition: ease-in-out transform 300ms;

      svg {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 3px 10px 0 0;
        transition: ease-in-out transform 300ms;
      }

      &:hover {
        color: #1ac6ff;

        svg {
          transform: scale(1.2);
          fill: #1ac6ff;
        }
      }

      &.active {
        color: #1ac6ff;

        svg {
          fill: #1ac6ff;
          transform: rotate(90deg);
        }
      }
    }

    .collapsible-body {
      padding: 0 0 0 110px;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 15px;
      border: 0;
      box-shadow: none;

      ul {
        li {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            display: block;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            width: 3px;
            height: 3px;
            background: #424242;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .article {
    .mobile-background {
      background: rgba(0, 0, 0, 0.7);
      color: #FFFFFF;
    }

    .collapsible {
      .collapsible-body {
        padding: 0 0 0 37px;
      }
    }
  }
}