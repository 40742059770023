/**
 * Created by Profesor08 on 08.08.2017.
 */

.bank {
  background: url(../images/bg-payment-terminal.jpg) no-repeat right top;
  background-size: auto 100%;

  .row {
    margin-bottom: 0;
  }

  .image-block {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .bank {
    background: none;

    .image-block {
      display: block;
      line-height: 0;

      img {
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
      }
    }
  }
}