/**
 * Created by Profesor08 on 16.09.2017.
 */
.counter-section {
  padding: 80px 0;
  color: #FFFFFF;
  text-align: center;

  .heading {
    font-size: 48px;
    font-weight: 300;
    color: #1AC6FF;

    span {
      color: #FFFFFF;
      display: inline-block;
      padding-left: 20px;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 300;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .col {
    position: relative;

    &:not(:last-child):after {
      content: "/";
      position: absolute;
      top: 0;
      left: 100%;
      transform: translate(-50%, 0);
      font-size: 45px;
      font-weight: 300;
      color: #FFFFFF;
    }
  }

  background: url(../images/shadow-top.png) repeat-x top,
  url(../images/shadow-bottom.png) repeat-x bottom,
  url(../images/info-section/call-center-bg-3.jpg);
  background-size: auto, auto, cover;
}