/**
 * Created by Profesor08 on 10.08.2017.
 */

.soft-images {
  padding: 30px 0;
  color: #595959;

  h2 {
    font-size: 28px;
    color: #1AC6FF;
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }

  p.details {
    font-weight: 500;
  }
}