/**
 * Created by Profesor08 on 03.08.2017.
 */

.about {
  position: relative;
  background: url(../images/triple-line-gray.png) repeat-y top center, url(../images/white-bg.jpg) center top;
  padding: 50px 0;

  .container {
    width: 100%;
    max-width: 1600px;
    position: relative;

    > .row {
      margin: 0;
    }
  }

  .row {
    position: relative;
  }

  .right-side {
    height: 100%;
    position: absolute;

    &.col {
      left: 50%;
    }
  }

  .about-item {
    margin-bottom: 30px;
    font-family: 'Roboto Condensed', sans-serif;

    .image {
      text-align: center;
      margin-bottom: 10px;
    }

    h3, p {
      text-align: center;
      padding: 0;
      margin: 0;
    }

    h3 {
      color: #000000;
      font-size: 19px;
      font-weight: 400;
    }

    p {
      color: #4e4e4e;
      font-size: 16px;
      font-weight: 300;
    }
  }

  .info-card {
    padding-left: 60px;

    .card-wrapper {
      padding: 20px;
    }

    h2 {
      color: #1ac6ff;
    }

    p {
      color: #4a4a4a;
      width: auto;
    }
  }

  @media only screen and (max-width: 599px) {
    background: url(../images/white-bg.jpg) center top;

    .right-side {
      position: static;
    }
  }

  @media only screen and (max-width: 992px) {

    .info-card {
      padding: 0;
    }
  }
}