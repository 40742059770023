/**
 * Created by Profesor08 on 17.09.2017.
 */

.integration-info {
  padding: 50px 0;
  background: url(../images/shadow-top.png) repeat-x top,
  url(../images/shadow-bottom.png) repeat-x bottom,
  url(../images/info-section/intergation-info.jpg) no-repeat center center;
  background-size: auto, auto, cover;

  .row {
    margin-bottom: 50px;
  }

  h2 {
    font-size: 30px;
    font-weight: 300;
    line-height: 48px;
    text-align: center;
    color: #1ac6ff;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    margin: 0;
    color: #ffffff;
  }

  @media only screen and (max-width: 600px) {
    img {
      margin: 15px 0;
      transform: rotate(90deg);
    }
  }
}