/**
 * Created by Profesor08 on 03.08.2017.
 */

.brands.main-page {
  background: url(../images/white-bg.jpg) center center;
  padding: 60px 0;

  .brands-carousel {
    .owl-item {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 130px;
      max-height: 70px;
      width: auto;
    }
  }
}

.brands:not(.main-page) {
  background: #F0F0F0;

  &:not(.multiple-lines) {
    .col {
      text-align: center;
      height: 100px;
      line-height: 130px;

      img {
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .col .row .col {
    text-align: center;
    height: 100px;
    line-height: 130px;

    img {
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.multiple-lines {
    .col {
      img {
        object-fit: cover;
      }
    }

    .container > .row:only-child {
      display: flex;
      flex-wrap: wrap;

      .info {
        display: flex;
        align-items: center;
      }
    }
  }

  p {
    font-size: 18px;
  }
}