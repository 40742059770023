/**
 * Created by Profesor08 on 10.08.2017.
 */

.section-form {
  background:
        url(../images/shadow-top.png) repeat-x top,
        url(../images/shadow-bottom.png) repeat-x bottom,
        url(../images/form-bg-1.jpg) no-repeat center center;
  background-size: auto, auto, cover;
  color: #595959;
  padding: 70px 0 50px 0;

  h2, p {
    text-align: center;
  }

  h2 {
    font-size: 30px;
    color: #3d3d3d;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    color: #525252;
    margin-top: 0;
  }

  a {
    color: #3d3d3d;

    &:hover {
      text-decoration: underline;
    }
  }

  .form-container {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
  }
}