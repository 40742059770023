/**
 * Created by Profesor08 on 15.08.2017.
 */

@side-nav-bg: #252525;
@side-color: #1ac6ff;
@side-item-border: #2a2a2a;

.side-nav {
  background: @side-nav-bg;
  color: @side-color;

  li {

    &.logo-container {
      border-bottom: 1px solid @side-item-border;
    }

    &.social-icons {
      padding-top: 15px;
    }

    a {
      color: @side-color;
      padding: 0 16px;

      &.store-icon {
        display: inline-block;
        width: auto;
        padding: 0;
        margin-left: 16px;

        &:hover {
          background: transparent;
        }
      }
    }

    &.menu-container {
      a {
        border-bottom: 1px solid @side-item-border;
      }
    }

    .collapsible-header {
      border-bottom: 1px solid @side-item-border;
    }
  }

  .collapsible-body, &.fixed .collapsible-body {
    background: darken(@side-nav-bg, 5%);

    li a {
      padding: 15px 23.5px 15px 31px;
      height: auto;
      line-height: normal;
      border-bottom: 1px solid darken(@side-item-border, 5%);
    }
  }

  .collapsible {
    li:last-child {
      border: 0;
    }
  }

}