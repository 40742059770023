/**
 * Created by Profesor08 on 10.08.2017.
 */

.soft-variants {
  padding: 30px 0;

  h2 {
    font-weight: 500;
    font-size: 24px;
    color: #1AC6FF;
  }

  p {
    color: #8d8d8d;
    font-weight: 300;
  }

  .responsive-img {
    width: 100%;
  }

  .col {
    position: relative;
  }

  .button-group {
    position: absolute;
    top: 60%;
    left: -18%;
  }
}

@media only screen and (max-width: 992px) {
  .soft-variants {

    h2, p {
      text-align: center;
    }

    .button-group {
      width: auto;
      text-align: center;
      position: static;

      .design-button {
        display: block;
        width: 184px;
        margin: 0 auto 2px auto;
      }
    }
  }
}