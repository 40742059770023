/**
 * Created by Profesor08 on 11.08.2017.
 */

.section-tabs {
  padding: 50px 0;

  .tab {
    border: 1px solid #E4E4E4;
    margin-right: 2px;
    font-size: 14px;
    font-weight: 300;
    background: #EBEBEB;

    a {
      color: #656565;

      &.active, &:active, &:hover {
        color: #1ac6ff;
      }
    }
  }

  .indicator {
    background: #1ac6ff;
  }

  .responsive-img {
    width: 100%;
  }
}