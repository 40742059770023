/**
 * Created by Profesor08 on 12.08.2017.
 */


.specs {
  padding: 50px 0;

  h3, svg, .heading, .spec-item, li {
    transition: ease-in-out all 300ms;
  }

  h2 {
    color: #595959;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 70px;
    margin-top: 0;
  }

  .heading {
    height: 100px;
    display: table;
    width: 100%;
    background: #EFF0F0;
  }

  .image {
    width: 120px;
    height: 100px;
    position: relative;

    svg {
      max-width: 68px;
      max-height: 68px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate3d(0, -50%, 0);
      fill: #1BC6FF;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 300;
    color: #424242;
    padding-left: 0;
    display: table-cell;
    vertical-align: middle;
  }

  ul {
    padding: 0 10px 0 30px;
  }

  .spec-item {
    height: 380px;
    margin-bottom: 45px;

    &.active, &:hover {
      background: #1BC6FF;
      cursor: pointer;

      .heading {
        background: #24ACDB;
      }

      .image svg {
        fill: #FFFFFF;
      }

      h3 {
        color: #FFFFFF;
      }
    }
  }
}