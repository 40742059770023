/**
 * Created by Profesor08 on 08.08.2017.
 */

.promo {
  background: url(../images/bg-promo-1.jpg) no-repeat center center;
  background-size: cover;
  color: #FFFFFF;
  padding: 30px 0;
  text-align: center;
  font-weight: 300;
  line-height: normal;

  h2 {
    font-size: 48px;
  }

  p {
    font-size: 24px;
  }
}