/**
 * Created by Profesor08 on 11.08.2017.
 */

.scope {
  padding: 50px 0;
  background: url(../images/mountains-bg.jpg) no-repeat;
  background-size: cover;
  font-weight: 300;

  h2 {
    font-size: 48px;
    color: #393939;
    text-align: center;
    font-weight: 300;
    margin-top: 0;
  }

  h3 {
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 300;
    margin: 0 10px 10px 0;
    line-height: normal;
  }

  p {
    margin: 0;
    line-height: normal;
    padding-right: 10px;
  }

  .row {
    margin-bottom: 0;
  }

  .item-container {
    min-height: 124px;
    position: relative;
    border-radius: 5px;
    padding: 15px 0 15px 130px;
    background: rgba(255, 255, 255, 0.6) no-repeat 37px center;
    margin-bottom: 15px;

    &.operator {
      background-image: url(../images/010-operator.png);
    }

    &.layers {
      background-image: url(../images/011-layers.png);
    }

    &.storage {
      background-image: url(../images/012-file-storage.png);
    }

    &.levels {
      background-image: url(../images/013-levels.png);
    }

    &.line-chart {
      background-image: url(../images/014-line-chart.png);
    }

    &.incoming-call {
      background-image: url(../images/015-incoming-call.png);
    }

    &.cloud-computing {
      background-image: url(../images/016-cloud-computing.png);
    }
  }

  //.col.s12:not(.m6) {
  //  background: rgba(255, 255, 255, 0.6);
  //  border-radius: 5px;
  //
  //  .item-container {
  //    background-color: transparent;
  //  }
  //}
}