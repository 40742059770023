/**
 * Created by Profesor08 on 04.08.2017.
 */

.footer {
  background: url(../images/white-bg.jpg) center top;
  padding-top: 40px;
  color: #4a4a4a;

  .logo {
    padding-bottom: 30px;
    text-align: center;
  }

  ul {
    margin: 0 0 20px 0;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;

    &:not(.logo-list) {
      padding-top: 25px;
    }

    li {
      line-height: 26px;

      &:first-child {
        text-transform: uppercase;
      }
    }

    a {
      color: #8d8d8d;

      &:hover {
        text-decoration: underline;
      }

      &.outlined {
        color: #139eff;
      }
    }
  }

  .logo-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    a {
      padding: 0 8px;
    }
  }

  p {
    text-align: center;
  }

  .store-icons img {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .footer {
    .logo-icons {
      flex-wrap: wrap;
    }
  }
}