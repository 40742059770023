/**
 * Created by Profesor08 on 11.08.2017.
 */

.section-slider {
  padding: 50px 0;

  .container {
    position: relative;
  }

  .prev, .next {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: url(../images/arrow-point-to-left.png) no-repeat;
    width: 35px;
    height: 60px;
    content: '';
    color: transparent;
    overflow: hidden;
  }

  .next {
    left: auto;
    right: 0;
    background: url(../images/arrow-point-to-right.png) no-repeat;
  }
}