/**
 * Created by Profesor08 on 10.08.2017.
 */

.info-section {
  background: no-repeat center center;
  background-size: cover;
  color: #FFFFFF;
  padding: 50px 0;
  text-align: center;
  line-height: normal;

  h2 {
    font-size: 48px;
    font-weight: 300;
  }

  p {
    font-size: 24px;
    font-weight: 300;
  }

  &.main-page {
    p {
      font-size: 18px;
      color: #595959;
    }
  }

  &.city-bg {
    background-image: url(../images/info-section/city-bg.jpg);
    background-size: cover;

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/city-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.pink-bg {
    background: url(../images/info-section/pink-bg.jpg) no-repeat center center;
    background-size: cover;

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/pink-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.dogi-bg {
    background: url(../images/info-section/dogi-bg.jpg) no-repeat center center;
    background-size: cover;

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/dogi-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.cols-bg {
    background-image: url(../images/info-section/cols-bg.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/cols-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.phone-in-hand {
    background-image: url(../images/info-section/phone-in-hand.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/phone-in-hand.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.people-on-road {
    background-image: url(../images/info-section/people-on-road.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/people-on-road.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.floor {
    background-image: url(../images/info-section/floor.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/floor.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.beautiful-girl {
    background-image: url(../images/info-section/beautiful-girl.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/beautiful-girl.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.city-connections {
    background-image: url(../images/info-section/city-connections.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/city-connections.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.space-mountains {
    background-image: url(../images/info-section/space-mountains.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/space-mountains.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.it {
    background-image: url(../images/info-section/info-it.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/info-it.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.call-center {
    background-image: url(../images/info-section/call-center-bg-3.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/call-center-bg-3.jpg);
      background-size: auto, auto, cover;
    }
  }

  &.teamwork {
    background-image: url(../images/info-section/teamwork.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/teamwork.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.city-in-fog {
    background-image: url(../images/info-section/city-in-fog.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/city-in-fog.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.sky-bg {
    background-image: url(../images/info-section/sky-bg.jpg);
    text-align: left;
    padding: 30px 0;
    background-size: cover;

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/sky-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }

    h2 {
      font-size: 36px;
      color: #595959;
    }

    p {
      color: #595959;
      font-size: 16px;
    }
  }

  &.mountains-evening-light {
    background-image: url(../images/info-section/mountains-evening-light.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/mountains-evening-light.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.forest-bg {
    background-image: url(../images/info-section/forest-bg.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/forest-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.forest-bg-2 {
    background-image: url(../images/info-section/forest-bg-2.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/forest-bg-2.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.night-city-1 {
    background-image: url(../images/info-section/night-city-1.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/night-city-1.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.mountains-evening {
    background-image: url(../images/info-section/mountains-evening-bg.jpg);

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/mountains-evening-bg.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }

    .partners-logos {
      h3 {
        font-size: 28px;
        font-weight: 300;
        color: #1ac6ff;
      }

      h4 {
        font-size: 43px;
        font-weight: 300;
        color: #1ac6ff;
      }

      p {
        font-size: 14px;
      }

      img {
        max-width: 100px;
        max-height: 70px;
      }
    }

    .partner-logo {
      line-height: 120px;
    }

    img {
      vertical-align: middle;
    }

    .round-diagrams {
      color: #FFFFFF;

      h3 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  &.mountains-evening-2 {
    background-image: url(../images/info-section/mountains-evening-2.jpg);
    background-size: cover;

    &.shadowed {
      background:
              url(../images/shadow-top.png) repeat-x top,
              url(../images/shadow-bottom.png) repeat-x bottom,
              url(../images/info-section/mountains-evening-2.jpg) no-repeat center center;
      background-size: auto, auto, cover;
    }
  }

  &.info-statistics {
    padding: 80px 0;

    .row {
      margin-bottom: 0;
    }

    .heading {
      font-size: 48px;
      font-weight: 300;
      color: #1AC6FF;

      span {
        color: #FFFFFF;
        display: inline-block;
        padding-left: 20px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 300;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &.no-bg {
    text-align: left;

    h2 {
      font-size: 36px;
      color: #1AC6FF;
    }

    p {
      color: #595959;
      font-size: 16px;
    }

    .main-text {
      font-size: 18px;
      font-weight: 400;
    }
  }
}