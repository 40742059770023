/**
 * Created by Profesor08 on 01.09.2017.
 */

.videos {
  padding: 50px 0;
  background: url(../images/shadow-top.png) repeat-x top, url(../images/shadow-bottom.png) repeat-x bottom;
  height: auto !important;

  .video-container {
    border: 4px solid #FFFFFF;
    border-radius: 25px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .video-link-list {
    margin-bottom: -15px;
  }

  .video-link-container {
    height: 94px;
    max-width: 140px;
    margin: 0 auto 15px auto;
  }

  .video-link {
    display: block;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    color: #4e4e4e;
    font-size: 14px;
    font-weight: 500;
    position: relative;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 3px;
      left: 3px;
      width: calc(100% - 5px);
      height: calc(100% - 6px);
      background: #FFFFFF;
      border-radius: 5px;
    }

    span {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #FFFFFF;
      border-radius: 5px;
      z-index: -1;
    }

    &:after {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 1px;
      left: 1px;
      background: linear-gradient(45deg, #7721a8 0%, #2ebdf2 100%);
      border-radius: 5px;
      z-index: -1;
    }

    &:before, &:after {
      opacity: 1;
      transition: ease opacity 250ms;
    }

    &:hover {

      &:before, &:after {
        opacity: 1;
      }
    }
  }
}