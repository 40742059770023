/**
 * Created by Profesor08 on 03.08.2017.
 */

.core {
  background:
          url(../images/shadow-top.png) repeat-x top,
          url(../images/shadow-bottom.png) repeat-x bottom;

  position: relative;
  height: 496px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 124px;
    background: url(../images/core-center-bg.png) no-repeat;
    z-index: 0;
  }


  .background-image {
    position: absolute;
    display: none;
    width: 100%;
  }

  .container, .row, .col {
    height: 100%;
  }

  .info-card {
    max-width: 530px;
    position: relative;
    z-index: 2;

    h2 {
      font-size: 50px;
      color: #1ac6ff;
    }

    p {
      font-size: 36px;
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 599px) {
    &:before {
      background: none;
    }
  }
}