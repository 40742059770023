/**
 * Created by Profesor08 on 02.09.2017.
 */

.icon-on-circle {
  background: url(../images/shadow-top.png) repeat-x top,
  url(../images/shadow-bottom.png) repeat-x bottom,
  url(../images/info-section/poligonal-big-bg.jpg) no-repeat center center;
  background-size: auto, auto, cover;
  position: relative;
  padding: 50px 0;

  .section-background {
    width: 100%;
    display: none;
  }

  .ellipse-item {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }

    .image {
      text-align: center;
    }
  }

  .ellipse-item {
    &.ellipse-text {
      color: #FFFFFF;
      cursor: default;
      width: auto;
      text-align: center;
      margin: 0;
      background: rgba(0, 0, 0, .41);
      padding: 18px 27px;
      border-radius: 15px;
    }
  }

  @media only screen and (min-width: 1366px) {
    min-height: 1000px;

    .ellipse {
      width: 656px;
      height: 656px;
      border: 5px solid #FFFFFF;
      border-radius: 50%;
      box-shadow: 0 0 12px 4px rgba(255, 255, 255, 0.75), 0 0 12px 4px rgba(255, 255, 255, 0.75) inset;
      background-image: linear-gradient(54deg,
      rgba(0, 42, 255, 0.3) 0%,
      rgba(80, 129, 255, 0.3) 27%,
      rgba(213, 128, 253, 0.3) 68%,
      rgba(212, 25, 229, 0.3) 100%);

      background-size: 800% 800%;
      animation: animatedgradient 7s ease alternate infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }

    &.virtual-ats {
      .ellipse {
        animation: none;
        background: url(../images/ellipse-inside-bg-2.png) no-repeat;
      }
    }

    .ellipse-item {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &.ellipse-text {
        color: #FFFFFF;
        cursor: default;
        width: 380px;
        text-align: center;
        margin: 0;
        background: none;
        padding: 0;
        border-radius: 0;
      }

      img {
        transition: ease transform 250ms;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        left: 0;
        width: 262px;
        margin: 0;
        transform: translate(0, -50%);
        transition: ease color 250ms;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }

        p {
          color: #0cda90;
        }
      }

      &.ellipse-item-drop {
        transform: translate(calc(~"-125px - 50%"), calc(~"-300px - 50%"));

        p {
          text-align: right;
          left: -20px;
          transform: translate(-100%, -50%);
        }
      }

      &.ellipse-item-shield {
        transform: translate(calc(~"-300px - 50%"), calc(~"-125px - 50%"));

        p {
          text-align: right;
          left: -20px;
          transform: translate(-100%, -50%);
        }
      }

      &.ellipse-item-imsi {
        transform: translate(calc(~"-300px - 50%"), calc(~"125px - 50%"));

        p {
          text-align: right;
          left: -20px;
          transform: translate(-100%, -50%);
        }
      }

      &.ellipse-item-number {
        transform: translate(calc(~"-125px - 50%"), calc(~"300px - 50%"));

        p {
          text-align: right;
          left: -20px;
          transform: translate(-100%, -50%);
        }
      }

      &.ellipse-item-pulse {
        transform: translate(calc(~"125px - 50%"), calc(~"300px - 50%"));

        p {
          text-align: left;
          left: calc(~"100% + 20px");
        }
      }

      &.ellipse-item-file {
        transform: translate(calc(~"125px - 50%"), calc(~"-300px - 50%"));

        p {
          text-align: left;
          left: calc(~"100% + 20px");
        }
      }

      &.ellipse-item-cli {
        transform: translate(calc(~"300px - 50%"), calc(~"-125px - 50%"));

        p {
          text-align: left;
          left: calc(~"100% + 20px");
        }
      }

      &.ellipse-item-phone {
        transform: translate(calc(~"300px - 50%"), calc(~"125px - 50%"));

        p {
          text-align: left;
          left: calc(~"100% + 20px");
        }
      }



      &.ellipse-item-gear {
        transform: translate(-50%, calc(~"-324px - 50%"));

        &:before {
          content: '';
          display: block;
          width: 67px;
          height: 78px;
          background: url(../images/ellipse-item-hover-indicator.png) no-repeat;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          transform: translate(-50%, 60px) rotate(-90deg);
          opacity: 0;
          transition: ease all 250ms;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        p {
          text-align: center;
          left: 50%;
          top: 0;
          transform: translate(-50%, -120%);
        }
      }

      &.ellipse-item-mail-closed {
        transform: translate(calc(~"-306px - 50%"), calc(~"-106px - 50%"));

        &:before {
          content: '';
          display: block;
          width: 67px;
          height: 78px;
          background: url(../images/ellipse-item-hover-indicator.png) no-repeat;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          transform: translate(16px, 26px) rotate(-162deg);
          opacity: 0;
          transition: ease all 250ms;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        p {
          text-align: right;
          left: -20px;
          transform: translate(-100%, calc(~"-50% - 10px"));
        }
      }

      &.ellipse-item-handset {
        transform: translate(calc(~"306px - 50%"), calc(~"-106px - 50%"));

        &:before {
          content: '';
          display: block;
          width: 67px;
          height: 78px;
          background: url(../images/ellipse-item-hover-indicator.png) no-repeat;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          transform: translate(-84px, 32px) rotate(-18deg);
          opacity: 0;
          transition: ease all 250ms;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        p {
          text-align: left;
          left: calc(~"100% + 20px");
        }
      }

      &.ellipse-item-cloud {
        transform: translate(calc(~"190px - 50%"), calc(~"262px - 50%"));

        &:before {
          content: '';
          display: block;
          width: 67px;
          height: 78px;
          background: url(../images/ellipse-item-hover-indicator.png) no-repeat;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          transform: translate(-60px, -32px) rotate(52deg);
          opacity: 0;
          transition: ease all 250ms;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        p {
          text-align: left;
          left: calc(~"100% + 20px");
        }
      }

      &.ellipse-item-mail-opened {
        transform: translate(calc(~"-190px - 50%"), calc(~"262px - 50%"));

        &:before {
          content: '';
          display: block;
          width: 67px;
          height: 78px;
          background: url(../images/ellipse-item-hover-indicator.png) no-repeat;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          transform: translate(-0px, -32px) rotate(126deg);
          opacity: 0;
          transition: ease all 250ms;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        p {
          text-align: right;
          left: -20px;
          transform: translate(-100%, -50%);
        }
      }
    }
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0 50%;
  }
}

