/**
 * Created by Profesor08 on 08.08.2017.
 */

@color_1: #595959;
@color_2: #1ac6ff;
@color_3: #FFFFFF;

.opportunities {
  font-weight: 300;

  .container {
    position: relative;
  }

  .collapsible-container {
    position: relative;
  }

  h2 {
    font-weight: 300;
    font-size: 36px;
    color: @color_1;
    text-align: center;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    color: @color_1;
    margin-bottom: 30px;

    &:nth-of-type(1) {
      font-size: 24px;
      color: @color_2;
    }
  }

  p {
    &:first-of-type {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .row {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .collapsible {
    border: 0;
    box-shadow: none;
    
    .collapsible-header {
      border: 1px solid #1AC6FF;
      border-right: 0;
      margin: 2px 0;
      padding: 12px 10px 12px 16px;
      font-size: 14px;
      line-height: 13px;
    }

    .collapsible-header.active {
      background: #1AC6FF;
      color: @color_3;
    }

    li.active {
      & + li {
        .collapsible-header {
          border-top: 1px solid #1AC6FF;
        }
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .collapsible-body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border: 0;
      box-shadow: none;
    }
  }

  &.call-center {
    .collapsible-container {
      position: relative;
      //min-height: 1400px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .opportunities {
    .collapsible {
      .collapsible-body.col.l9 {
        width: 75%;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .opportunities {
    .collapsible-container {
      min-height: 0;
    }

    .collapsible {
      border: 1px solid #1AC6FF;
      .collapsible-header {
        border: 0;
        border-bottom: 1px solid #1AC6FF;
        margin: 0;
      }

      .collapsible-body {
        position: static;
        width: 100%;
      }

      .collapsible-body.col.l9 {
        width: 100%;
      }
    }
  }
}

