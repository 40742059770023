/**
 * Created by Profesor08 on 02.09.2017.
 */

.info-text-section {
  background: url(../images/white-bg.jpg) center top;
  position: relative;

  &:not(.no-padding), .info-padding {
    padding: 100px 0;
  }

  h3 {
    font-size: 30px;
    font-weight: 300;
    color: #1ac6ff;
    margin-top: 0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;

    &.outlined {
      font-size: 18px;
      font-weight: 400;
      color: #4a4a4a;
    }
  }

  .row {
    margin-bottom: 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .background-image {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 0;
    max-width: 50%;
    object-fit: cover;
    object-position: right center;

    &.align-right {
      right: 0;
      object-position: left center;
    }

    &.align-left {
      left: 0;
      object-position: right center;
    }

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  &.client-service {
    background: url(../images/shadow-top.png) repeat-x top,
    url(../images/shadow-bottom.png) repeat-x bottom,
    url(../images/info-section/client-service.jpg) no-repeat left top;
    background-size: auto, auto, cover;
    color: #ffffff;

    p.outlined {
      color: #ffffff;
    }
  }

  &.connections {
    padding: 50px 0;
    //height: 756px;

    //background: url(../images/connections.png) no-repeat center top 50px,
    //url(../images/white-bg.jpg) center top;
    //background-size: auto 100%, auto;

    background: url(../images/white-bg.jpg) center top;
    background-size: auto;

    p {
      font-size: 24px;
      font-weight: 500;
      color: #646464;
    }
  }

  &.face-space {
    height: 770px;
    background: url(../images/shadow-top.png) repeat-x top,
    url(../images/shadow-bottom.png) repeat-x bottom,
    url(../images/main/face-space.jpg) no-repeat center center;
    background-size: auto, auto, cover;

    @media only screen and (max-width: 992px) {
      height: auto;
    }

    p {
      color: #e5e5e5;
      font-size: 18px;
      font-weight: 300;

      span {
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }

  /*
  .container {
    height: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    height: 100%;
  }

  &.vip-sim-security {
    padding-bottom: 0;

    .row {
      .col:first-child {
        padding: 0 0 50px 0;
      }
    }
  }

  &.client-service {
    background: url(../images/shadow-top.png) repeat-x top,
    url(../images/shadow-bottom.png) repeat-x bottom,
    url(../images/info-section/client-service.jpg) no-repeat left top;
    background-size: auto, auto, cover;
    color: #ffffff;

    .col {
      margin-left: 0;
    }

    p {
      &.outlined {
        color: #ffffff;
        font-size: 23px;
      }
    }
  }

  &.partnership {
    background: url(../images/info-section/info-partnership.png) no-repeat left center;
    background-size: auto 100%;
    height: 521px;
  }

  &.partnership-2 {
    background: url(../images/info-section/info-partnership-2.png) no-repeat right center;
    background-size: auto 100%;
    height: 485px;

    .col {
      margin-left: 0;
    }
  }
  */
}