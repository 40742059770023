/**
 * Created by Profesor08 on 03.08.2017.
 */

.icon-sprite-loop(@offset, @counter, @left, @top) when (@counter >= 0) {
  .icon-sprite-loop((@offset - 1), (@counter - 1),@left, @top);

  &.icon-@{offset}:before {
    background-position: @left (@top * @counter);
  }
}

.icon-sprite {
  position: relative;

  &:before {
    content: '';
    background: url(../images/icons.jpg) no-repeat;
    position: absolute;
    width: 57px;
    height: 58px;
    left: 0;
    top: 0;
  }

  .icon-sprite-loop(8, 7, 0, -58px);
  .icon-sprite-loop(16, 7, -57px, -58px);
  .icon-sprite-loop(24, 7, -114px, -58px);
  .icon-sprite-loop(32, 7, -171px, -58px);
}

.floating-icon {
  position: relative;
}

.whatsup {
  width: 182px;
  height: 180px;
  background: url(../images/whatsup.png) no-repeat;
  position: absolute;
  left: 82%;
  z-index: 3;
  transform: translate3d(0, -114px, 0);
}

@media only screen and (max-width: 1300px) {
  .whatsup {
    display: none;
  }
}