/**
 * Created by Profesor08 on 12.08.2017.
 */


.design-form {

  h3 {
    color: #3d3d3d;
    font-size: 30px;
    text-align: center;
  }

  label {
    padding-left: 15px;
  }

  .input-field {
    height: 48px;
    padding: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 48px;
      width: 3px;
      background: #1AC6FF;
    }

    input[type=text], input[type=password] {
      border: 2px solid transparent;
      background: #FFFFFF;
      position: relative;
      margin: 0 0 0 3px;
      text-indent: 10px;
      font-size: 1rem;
      box-sizing: border-box;
      height: 48px;
      width: calc(~"100% - 3px");

      &:focus {
        box-shadow: none;
        border: 2px solid transparent;

        + label {
          color: #1AC6FF;
        }
      }
    }
  }

  .input-field label:not(.label-icon).active {
    -webkit-transform: translateY(-18px) scale(0.8);
    transform: translateY(-18px) scale(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .links-container {
    margin-bottom: 15px;
  }

  .form-button-container {
    text-align: center;

    button {
      width: 100%;
    }
  }
}