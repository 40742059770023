/**
 * Created by Profesor08 on 12.08.2017.
 */

ul.design-list {
  li {
    background: url(../images/list-icon-check.png) no-repeat left 4px;
    padding: 0 0 0 30px;
    margin: 11px 0;
    border: 0;
    box-shadow: none;
  }

  li.buttons-container {
    padding: 0;
    background: transparent;
  }

  &.blue-icon {
    li {
      background: url(../images/list-icon-check-blue.png) no-repeat left 4px;
    }
  }

  &.blue-icon-2 {
    li {
      padding: 0 0 0 25px;
      background: url(../images/list-icon-check-blue-2.png) no-repeat left 4px;
    }
  }

  &.grey-icon {
    li {
      background: url(../images/list-icon-check-grey.png) no-repeat left 4px;
    }
  }

  &.grey-blue-icon {
    li {
      background: url(../images/list-icon-check-grey-blue.png) no-repeat left 4px;
    }
  }
}