/**
 * Created by Profesor08 on 03.08.2017.
 */

.infographics {
  height: 700px;
  background:
          url(../images/shadow-top-2.png) repeat-x top,
          url(../images/shadow-bottom-2.png) repeat-x bottom,
          url(../images/infographics-white-2.png) no-repeat center center;

  .parallax img {
    height: 170%;
  }
}

