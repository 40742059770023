/**
 * Created by Profesor08 on 03.08.2017.
 */

h1, h2 {
  font-weight: 300;

  span {
    color: #1ac6ff;
  }
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

@button-background: #1AC6FF;

.design-button {
  padding: 9px 19px;
  background: @button-background;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  color: #FFFFFF;
  text-transform: lowercase;
  border: 0;
  border-radius: 3px;
  transition: ease-in-out all 300ms;
  margin-top: 5px;
  margin-bottom: 5px;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover, &:focus {
    background: lighten(@button-background, 10%);
  }

  &.border-white, &.border-blue {
    border: 2px solid #FFFFFF;
    background: transparent;
    padding: 7px 17px;
  }

  &.border-blue {
    border: 2px solid @button-background;
    color: @button-background;
  }

  &.waves-blue .waves-ripple {
    background: rgba(26, 198, 255, 0.4);
  }

  &.pdf {
    background: url(../images/pdf-icon.png) no-repeat 10px center;
    color: @button-background;
    border: 2px solid @button-background;
    padding-left: 36px;
  }

  &.border-white {
    &:hover {
      border-color: @button-background;
      color: @button-background;
    }
  }
}

.button-group {
  width: 184px;

  .design-button {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    border-width: 1px;
  }
}

.full-height {
  height: 100%;
}

.info-card {
  text-align: left;
  display: table;
  height: 100%;
  min-height: inherit;

  h2, p {
    font-weight: 300;
  }

  h2 {
    font-size: 48px;
    color: #FFFFFF;
    line-height: 48px;
    margin: 0 0 18px 0;

    span {
      color: #1ac6ff;
    }
  }

  p {
    font-size: 19px;
    color: #FFFFFF;
  }

  p.details {
    font-weight: 400;
  }

  @media only screen and (max-width: 599px) {
    text-align: center;
  }
}

.progress {
  background: #D8F5FF;
  border-radius: 3px;
  height: 24px;

  .determinate {
    background: #1AC6FF;
  }
}

@media only screen and (max-width: 992px) {
  .info-card {
    h2 {
      font-size: 40px;
      line-height: normal;
    }

    p {
      width: 100%;
    }
  }

  .mobile-background {
    background: rgba(0, 0, 0, 0.7);
  }

  p .mobile-background {
    color: #FFFFFF;
  }
}

.material-tooltip {

  transform: translateY(-50%) translateX(0) !important;
  max-width: 350px;
  border-radius: 5px;
  color: #3d3d3d;
  text-align: left;
  padding: 20px;
  font-size: 16px;
  font-weight: 300;

  span span {
    font-size: 24px;
    font-weight: 500;
  }

  .backdrop {
    background: #FFFFFF;
  }
}

.table {
  display: table;
  width: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.v-center {
  display: flex;
  align-items: center;
}

.v-bottom {
  vertical-align: bottom;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.v-hidden {
  visibility: hidden;
}

.no-margin {
  margin: 0;
}

.container-left-bottom {
  position: relative;
  top: 100%;
  left: 0;
  transform: translateY(-100%);
}

.center-content-container {
  @media only screen and (min-width: 601px) {
    display: flex;
    align-items: center;
  }
}

.invert-light {
  color: #FFFFFF !important;
}

.invert-dark {
  color: #454545 !important;
}

.s-invert-light {
  @media only screen and (max-width: 600px) {
    color: #FFFFFF !important;
  }
}

.s-invert-dark {
  @media only screen and (max-width: 600px) {
    color: #454545 !important;
  }
}

.m-invert-light {
  @media only screen and (max-width: 992px) {
    color: #FFFFFF !important;
  }
}

.m-invert-dark {
  @media only screen and (max-width: 992px) {
    color: #454545 !important;
  }
}