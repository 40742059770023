/**
 * Created by Profesor08 on 03.08.2017.
 */

.main {
  position: relative;
  min-height: 600px;
  background-position: left top, left bottom, center center;
  background-repeat: repeat-x, repeat-x, no-repeat;
  background-size: auto, auto, cover;

  h2, h3 {
    font-weight: 300;
    margin: 0 0 0 0;

    span {
      color: #1ac6ff;
    }
  }

  h2 {
    font-size: 45px;
    color: #FFFFFF;
  }

  h3 {
    font-size: 36px;
    color: #1ac6ff;
    margin: 15px 0 0 0;
  }

  p {
    margin: 20px 0 20px 0;
    font-size: 19px;
    font-weight: 300;
    color: #FFFFFF;
  }

  &.start-page {
    background: url(../images/main/main-bg-4.png),
    url(../images/white-bg.jpg);
    background-position: center top, center top;
    background-repeat: no-repeat, repeat;
    background-size: auto, auto;

    &:before {
      content: '';
      background: url(../images/figure-3.png) no-repeat;
      width: 295px;
      height: 158px;
      position: absolute;
      left: 50%;
      bottom: -3px;
      transform: translate3d(-156px, 0, 0);
    }

    .background-image {
      width: 100%;
      display: none;
    }

    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media only screen and (min-width: 1600px) {
      height: auto;
      background: url(../images/white-bg.jpg) center top;
      font-size: 0;
      padding: 0;

      .background-image {
        display: inline-block;
      }
    }
  }

  &.crm-finance {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/crm-finance.jpg);
  }

  &.it {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/it-development.jpg);
  }

  &.vip-sim-security {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/vip-sim-security.jpg);
  }

  &.hr-agency {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/hr-agency.jpg);
  }

  &.api-integration {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/integration.jpg);
  }

  &.sms-delivery {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/sms-delivery.jpg);
    min-height: 960px;

    .row {
      min-height: 430px;
      margin: 0;

      &:last-child {
        h2 {
          font-size: 32px;
          margin-bottom: 20px;
        }
      }

      .col {
        margin: 0;
      }
    }
  }

  &.crm-banks {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/crm-banks.jpg);
  }

  &.api-gateway {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/api-gateway.jpg);
  }

  &.virtual-ats {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/virtual-ats.jpg);
  }

  &.partnership {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/partnership.jpg);
  }

  &.conference-round-table {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/conference-round-table.jpg);
  }

  &.conference {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/conference.jpg);
  }

  &.shop {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/crm-shop.jpg);
  }

  &.main-page {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/main-page-bg.jpg);
  }

  &.crm-marketing {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/crm-marketing.jpg);
  }

  &.trading-bg {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/trading-bg.jpg);
  }

  &.mfo-bg {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/mfo-bg.jpg);
  }

  &.deal {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/deal.jpg);
  }

  &.queue {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/queue.jpg);
  }

  &.training {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/training-bg.jpg);
  }

  &.marketing {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/marketing-bg.jpg);
  }

  &.balconies {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/balconies.jpg);
  }

  &.call-center-1 {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/call-center-bg-1.jpg);
  }

  &.call-center-2 {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/call-center-bg-2.jpg);
  }

  &.building-bg {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/building-bg.jpg);
  }

  &.webinar {
    background-image: url(../images/shadow-top.png),
    url(../images/shadow-bottom.png),
    url(../images/main/webinar-bg.jpg);

    .design-form {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      padding: 20px 20px 70px 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      .design-button {
        display: block;
        width: 100%;
        font-size: 16px;
      }

      h3 {
        margin: 10px 0 30px 0;
      }

      .button-group {
        width: auto;
        margin-top: 10px;
      }

      a {
        line-height: 50px;
        vertical-align: middle;
        color: #3d3d3d;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.ip-start {
    background-image: url(../images/main/polygonal-bg.jpg);
    font-weight: 300;
    font-size: 14px;
    position: relative;

    .container {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .row {
      margin-bottom: 0;
    }

    .background-container {
      border-radius: 3px;
      background-color: rgb(237, 238, 238);
      box-shadow: 5.755px 5.557px 0 0 rgba(0, 0, 0, 0.18);
    }

    .items-group {
      border-radius: 3px;
      background-color: rgb(255, 254, 255);
      box-shadow: 0 0 16.2px 1.8px rgba(0, 0, 0, 0.28);
      margin: 1rem 0.25rem;
      overflow: hidden;

      .col {
        padding: 0;
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .item-container {
      height: 114px;
      display: table;
      width: 100%;
      vertical-align: middle;
      border: 0;
      border-collapse: collapse;
    }

    .image {
      display: table-cell;
      width: 114px;
      height: 114px;
      text-align: center;
      vertical-align: middle;

      &.bg-red {
        background: #DC6F8C;
      }

      &.bg-red-grey {
        background: #635C70;
      }

      &.bg-blue {
        background: #2EA7CD;
      }

      &.bg-blue-grey {
        background: #5D7279;
      }
    }

    .text {
      display: table-cell;
      vertical-align: middle;
      padding-left: 0.75rem;
      color: #3f3f3f;
    }
  }

  &.main-about {
    background: url(../images/main/main-bg-4.png),
    url(../images/white-bg.jpg);
    background-position: center top, center top;
    background-repeat: no-repeat, repeat;
    background-size: auto, auto;

    .background-image {
      width: 100%;
      display: none;
    }

    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media only screen and (min-width: 1600px) {
      height: auto;
      background: url(../images/white-bg.jpg) center top;
      font-size: 0;
      padding: 0;

      .background-image {
        display: inline-block;
      }
    }

    .container {
      height: 116px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .main {
    padding-top: 110px;

    &.start-page, &.main-about {
      padding-top: 0;

      .col {
        left: 0;
        top: 110px;
        transform: translate(0, 0);
      }
    }
  }
}