/**
 * Created by Profesor08 on 03.08.2017.
 */

.services-small {
  padding: 80px 30px;
  color: #ffffff;
  height: auto;
  background: url(../images/shadow-top.png) repeat-x top,
  url(../images/shadow-bottom.png) repeat-x bottom;

  .row {
    margin-bottom: -30px;
  }

  .service-item {
    position: relative;
    margin-bottom: 15px;

    img {
      width: 58px;
    }

    .text-centered {
      position: absolute;
      left: 80px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}